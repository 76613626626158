<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="6"
          ><h6 class="mb-1">
            Profilim
          </h6></b-col>
        </b-row>
        <b-row>
          <b-col
            xs="12"
            md="6"
          >
            <b-form-group label="Adı Soyadı">
              <b-form-input
                v-model="formData.name"
                placeholder="Adı Soyadı"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="6"
          >
            <b-form-group label="E-Posta">
              <b-form-input
                v-model="formData.email"
                placeholder="E-Posta"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <label for="password">Parola</label>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="password"
              v-model="formData.password"
              :type="passwordFieldType"
              placeholder="Parola"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-card-text>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'

export default {
  name: 'Profile',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      detailForm: false,
      submitStatus: false,
      profileData: JSON.parse(localStorage.getItem('userData')),
      formData: {
        id: null,
        name: null,
        email: null,
        password: null,
        status: null,
        id_user_types: null,
      },
      statues: [
        { id: 1, title: 'Aktif' },
        { id: 0, title: 'Pasif' },
      ],
    }
  },
  computed: {
    userData() {
      return this.$store.getters['users/getUser']
    },
    saveStatus() {
      const status = this.$store.getters['users/getUserSaveStatus']
      return status
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    userData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getUserTypes()
    this.getUser()
  },
  methods: {
    getUserTypes() {
      this.$store.dispatch('userTypes/user_typesList', {
        select: ['user_types.id AS id', 'user_types.title AS title'],
      })
    },
    getUser() {
      this.$store.dispatch('users/userView', this.profileData.id)
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('users/userSave', this.formData)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
